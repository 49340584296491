const translations = {
  en: {
    language: 'English',
    menu: {
      pageTitle: 'Your future awaits : Embrace the journey of finding your dream job',
      liveJobs:"live jobs",
      wehave:"We offer thousands of live job opportunities",
      pageDescr:" Find and prepare for your dream jobs,pass tests and assess your skills, unlock new opportunities, showcase your strengths, create well-crafted resumes",
      livejobs2:"We have plenty of live Jobs",
      RequestDemo:"Request Demo",
      BrowserFeatures:"Browser Features",
      BrowserFeaturesDesc1:"Experience our website and more to make your browsing faster, safer and more tailored to your .",
      BrowserFeaturesDesc2:"These features collectively enhance browsing efficiency, personalization and security ",
      NewRandomJobs:"New & Random Jobs",
      NewRandomJobsDesc1:"Unlock your potential : the right job is just a click away",
      NewRandomJobsDesc2:"Effortless connect with the best freelancers for your needs ",
      forCandidate:"For Candidate",
      forRecruiter:"For Recruiter",
      candiadtesGuide:"How It Works for Candidates",
      candidatesGuideDesc:"Get started with your application",
      candidatestep1Title:"Create an account ",
      candidatestep1:"Sign up by providing your basic details and creating a profile. This helps us underestand your skills, experience and career goals",
      candidatestep2Title:"Complete your profile",
      candidatestep2:"Fill out your profile with information about your qualifications, work history and preferences. This ensures we match you with relevant opportunities",
      candidatestep3Title:"Browse opportunities",
      candidatestep3:"Explore available job listing or projects that align with your skills and interests",
      candidatestep4Title:"Submit application",
      candidatestep4:"Apply to the positions or projects that interst you. Tailor your application to highlight how your background fits the role",
      candidatestep5Title:"Get matched",
      candidatestep5:"Our system reviews your profile and applications, then matches you with the most suitable opportunities",
      candidatestep6Title:"Play coding games",
      candidatestep6:"Sharpen your coding skills through fun games.",

      recruiterGuide:"How It Works for Recruiters",
      recruiterGuideDesc:"How the process works for recruiters.",
      recruitertep1Title:"Create an account ",
      recruitertep1:"Sign up by providing your basic details and creating a profile. This helps us underestand your needs, efficiently and effectively match candidates with job opportunities",
      recruitertep2Title:"Create detailed job description",
      recruitertep2:"Ensure job descriptions are precise and include key responsabilities, required qualifications and desired skills",
      recruitertep3Title:"Establish evaluation criteria",
      recruitertep3:"Develop clear criteria for assessing candidates (technical, psycho-technicaland personality) to ensure consistency and fairness in the selection process",
      recruitertep4Title:"Streamline application process",
      recruitertep4:"Monitor and improve employee performance using data- driven insights ",
      recruitertep5Title:"Enhance candidate experience",
      recruitertep5:" Ind and hire talented candidates for your projects ",
      
      checkOur:"Check our",
      latestJob:"latest job openings now",
      descCheck:"Share your project with us, and we will swiftly connect you with the perfect freelancers",
      HappyCandidates:"Happy Candidates",
      HappyCandidatesDesc:"Tell us about your project, and we will promptly pair you with the ideal freelancers",
      FastTrack:"Fast track your success and quick career tips",
      FastTrackDesc:"Submit your project details, and we will quickly find the right freelancers for you",
      hirecueFooter:"Find the talent that will make the difference in your company.",
      hirecueFooterTit:"With Hirecue,",
      Pricing:" Pricing",
      AboutUs :"About Us ",
      SignIn:"Sign In",
      SignUp:"Sign Up",
      email:"Email",
      phone:"Phone",
      type:"Type",
      Customdemo:"Custom demo",
      Generaldemo:"General demo",
      description:"Description",
      close:"Close",
      submit:"Submit",
      Jobs:"Jobs and careers",
      ApplyNow:"Apply Now",
      Skills:"Skills",
      Experience:"Experience", 
      Offres:"Job offers",
      viewMore:"View More",
      startFree:"Start for free now",
      inscriptionGratuite:"Sign up for free !",
      general:"general",
      generalQuestion1: "How do I create an account on the platform?",
      generalAnswer1:`1. Click on "Sign up for free !" at the top of the page.
                     2. Fill in your details and confirm.
                     3. You will receive a verification e-mail. Click on the link to activate your account.
                     4. Log in and choose your role (Candidate, Recruiter, Company)..,`,
      generalQuestion2: "What roles are available and what are their benefits?",
      generalAnswer2:`- **Candidate**: Apply for jobs and take tests (Free).
                 - **Recruiter**: Access to job creation and test tools for individual recruiters.
                 - **Company**: Advanced features for businesses, including team management.`,
      generalQuestion3: "How do I choose a role after registering?",
      generalAnswer3:`After checking your e-mail address, log in and choose your role.
               If you choose "Recruiter" or "Company", an administrator will contact you to guide you and discuss subscriptions.`,
               subscription: `Subscription`,
               subscriptionQuestion1: `What subscriptions are available to recruiters and companies?`,
               subscriptionAnswer1:`We offer monthly and annual plans with unlimited testing options, CV analysis, and pre-screening tools.`,
               subscriptionQuestion2: `How does the subscription process work?`,
               subscriptionAnswer2:`1. Choose the "Recruiter" or "Company" role.
                        2. An administrator will contact you to explain the subscription options.
                        3. Make payment by bank transfer.
                        4. Your account will be activated within 24 hours of confirmation of payment`,
               payment: `Payment`,
               paymentQuestion1: `Which payment methods do you accept?`,
               paymentAnswer1:`We accept credit cards, PayPal, as well as payments via Stripe and Payme for annual payments`,
               paymentQuestion2: `Will I receive an invoice after payment?`,
               paymentAnswer2:`Yes, an invoice is automatically generated and available in your dashboard after each payment`,
               paymentQuestion3: `How does payment via Stripe and Payme work?`,
               paymentAnswer3:`We use Stripe and Payme to guarantee secure payments. You can choose one of these payment methods when you subscribe, and the payment process is quick and easy`,
               support: `Support`,
               supportQuestion1: `How do I contact customer support?`,
               supportAnswer1:`You can contact us using the form on the "Contact us" page or send an e-mail to contact@hirecue.com.`,
               supportQuestion2: `Is there any documentation for new users?`,
               supportAnswer2:`Yes, video tutorials and full documentation are available to help you use the platform.`,
               additional:"Additional",
               additionalQuestion1: "What are candidates?",
               additionalAnswer1:`Candidates are individuals who apply for job offers and participate in assessments on the platform.`,
               additionalQuestion2: "How does Hirecue protect my data?",
               additionalAnswer2:`Hirecue uses encryption and security protocols to ensure your data is protected at all stages of use.`,
               additionalQuestion3: "What's the difference between an assessment and a test?",
               additionalAnswer3:`An assessment evaluates the candidate's overall abilities and skills, while a test is a specific evaluation of one particular skill.`,
               additionalQuestion4: "Is Hirecue easy to use?",
               additionalAnswer4:`Yes, Hirecue is designed with a user-friendly interface that makes it easy for both recruiters and candidates to navigate.`,
               additionalQuestion5: "What type of support do you offer?",
               additionalAnswer5:`We offer email support, as well as phone support for premium subscribers. Additionally, we have a comprehensive FAQ and knowledge base.`,
               additionalQuestion6: "I have more questions.",
               additionalAnswer6:`If you have more questions, feel free to reach out to our support team via the 'Contact Us' page or send an email to support@hirecue.com.`,
              contacterNous:"Contact us" ,
              envoiemail:"Send an e-mail",
              choosePlan:"Choose Your Plan",
              choosePlanDesc:"Choose the plan that fits your needs and start your journey today!",
              monthly:"Monthly",
              annual:"Annual",
              noJobsAvailable: "No jobs available at the moment.",
              errorFetchingJobs: "Error retrieving job offers.",
              loading: "Loading in progress...",
            }
    }
,
  fr: {
  language: 'Français',
  menu: {
    pageTitle: "Votre avenir vous attend : Découvrez l'aventure de la recherche du travail de vos rêves",
    liveJobs : " emplois en direct ",
      wehave : " Nous proposons des milliers d'offres d'emploi en direct ",
      pageDescr : " Trouvez et préparez-vous à l'emploi de vos rêves, passez des tests et évaluez vos compétences, débloquez de nouvelles opportunités, mettez en valeur vos points forts, créez des CV bien rédigés ",
      livejobs2 : " Nous avons de nombreux emplois en direct ",
      RequestDemo : " Demander une démonstration ",
      BrowserFeatures : "Fonctionnalités du navigateur ",
      BrowserFeaturesDesc1 : "Découvrez notre site Web et plus encore pour rendre votre navigation plus rapide, plus sûre et mieux adaptée à vos besoins ",
      BrowserFeaturesDesc2 : "Ces fonctionnalités améliorent collectivement l'efficacité, la personnalisation et la sécurité de la navigation ",
      NewRandomJobs : "Emplois nouveaux et aléatoires ",
      NewRandomJobsDesc1 : "Libérez votre potentiel : le bon emploi est à portée de clic ",
      NewRandomJobsDesc2 : "Connectez-vous sans effort avec les meilleurs freelances pour vos besoins ",
      forCandidate : "Pour le candidat ",
      forRecruiter : "Pour le recruteur ",
      candiadtesGuide : "Comment ça marche pour les candidats ",
      candidatesGuideDesc : "Commencez votre candidature ",
      candidatestep1Title : "Créez un compte ",
      candidatestep1 : "Inscrivez-vous en fournissant vos informations de base et en créant un profil. Cela nous aide à comprendre vos compétences, votre expérience et vos objectifs de carrière",
      candidatestep2Title : "Complétez votre profil ",
      candidatestep2 : "Complétez votre profil avec des informations sur vos qualifications, votre expérience professionnelle et vos préférences. Cela nous permet de vous proposer des opportunités pertinentes ",
      candidatestep3Title : "Parcourir les opportunités ",
      candidatestep3 : "Explorez les offres d'emploi ou les projets qui correspondent à vos compétences et à vos intérêts ",
      candidatestep4Title : "Soumettre une candidature ",
      candidatestep4 : "Postulez aux postes ou aux projets qui vous intéressent. Adaptez votre candidature pour mettre en évidence la manière dont votre expérience correspond au poste ",
      candidatestep5Title : "Get matched ",
      candidatestep5 : "Notre système examine votre profil et vos candidatures, puis vous met en relation avec les opportunités les plus appropriées ",
      candidatestep6Title : "Play coding games ",
      candidatestep6: "Améliorez vos compétences en codage grâce à des jeux amusants ",
      recruiterGuide : "Comment ça marche pour les recruteurs ",
      recruiterGuideDesc : "Comment le processus fonctionne pour les recruteurs ",
      recruitertep1Title : "Créer un compte ",
      recruitertep1 : "Inscrivez-vous en fournissant vos informations de base et en créant un profil. Cela nous permet de mieux comprendre vos besoins et de vous mettre en relation de manière efficace et efficiente.",
      recruitertep2Title : " Créer une description de poste détaillée ",
      recruitertep2 : " Veiller à ce que les descriptions d'emploi soient précises et comprennent les principales responsabilités, les qualifications requises et les compétences souhaitées ",
      recruititertep3Title : " Établir des critères d'évaluation ",
      recruitertep3 : " Élaborer des critères clairs pour l'évaluation des candidats (techniques, psychotechniques et de personnalité) afin de garantir la cohérence et l'équité du processus de sélection ",
      recruititertep4Title : " Rationaliser le processus de candidature ",
      recruitertep4 : " Surveiller et améliorer les performances des employés en utilisant des données ",
      recruitertep5Title : " Améliorer l'expérience des candidats ",
      recruitertep5 : " Trouvez et embauchez des candidats talentueux pour vos projets ",

      checkOur : " Vérifier notre ",
      latestJob : " Dernières offres d'emploi en cours ",
      descCheck : " Partagez votre projet avec nous, et nous vous mettrons rapidement en contact avec les freelances parfaits ",
      HappyCandidates : " Candidats heureux ",
      HappyCandidatesDesc : " Parlez-nous de votre projet et nous vous mettrons rapidement en relation avec les freelances idéaux ",
      FastTrack : " Accélérez votre réussite et obtenez des conseils rapides sur votre carrière ",
      FastTrackDesc : " Soumettez-nous les détails de votre projet et nous trouverons rapidement les freelances qu'il vous faut ",
      hirecueFooter : "  Trouvez les talents qui feront la différence dans votre entreprise.",
      hirecueFooterTit:"Avec Hirecue,",
      Pricing:" Tarification ",
      AboutUs :" A propos de nous ",
      SignIn:"Se connecter",
      SignUp:"S'inscrire",
      email : " Email ",
      phone : " Téléphone ",
      type : " Type ",
      Customdemo : " Démonstration personnalisée ",
      Generaldemo : " Démonstration générale ",
      description : " Description ",
      close : " Fermer ",
      submit : " Envoyer ",
      Jobs:"Emplois et carrières",
      ApplyNow : " Postuler maintenant ",
      Skills : " Compétences ",
      Experience : " Expérience ",
      Offres:"Offres d'emploi",
      viewMore:"Voir plus",
      startFree:"Démarrer gratuitement maintenant",
      inscriptionGratuite:"S'inscrire gratuitement !",
      general :` Général `,
      generalQuestion1 :` Comment créer un compte sur la plateforme ? `,
      generalAnswer1:`1. Cliquez sur "Register " en haut de la page.
                     2. Remplissez vos coordonnées et confirmez.
                     3. Vous recevrez un e-mail de vérification. Cliquez sur le lien pour activer votre compte.
                     4. Connectez-vous et choisissez votre rôle (Candidat, Recruteur, Entreprise)...,`,
      generalQuestion2 :` Quels sont les rôles disponibles et quels sont leurs avantages ? `,
      generalAnswer2:`- **Candidat** : Postulez à des emplois et passez des tests (gratuit).
                 - **Recruteur** : Accès aux outils de création d'emploi et de test pour les recruteurs individuels.
                 - Entreprise** : Fonctionnalités avancées pour les entreprises, y compris la gestion des équipes`,
      generalQuestion3 : `Comment puis-je choisir un rôle après m'être inscrit ? `,
     
      generalAnswer3:`Après avoir vérifié votre adresse e-mail, connectez-vous et choisissez votre rôle.
               Si vous choisissez 'Recruteur' ou 'Entreprise', un administrateur vous contactera pour vous guider et discuter des abonnements.`,
      subscription:"Subscription",
      subscriptionQuestion1: "Quels sont les abonnements disponibles pour les recruteurs et entreprises ?",
      subscriptionAnswer1:`Nous proposons des plans mensuels et annuels avec options de tests illimités, analyse de CV, et outils de pré-sélection.`,
      subscriptionQuestion2: "Comment fonctionne le processus d'abonnement ?",
      subscriptionAnswer2:`1. Choisissez le rôle 'Recruteur' ou 'Company'.\n
               2. Un administrateur vous contactera pour vous expliquer les options d'abonnement.\n
               3. Effectuez le paiement par virement.\n
               4. Votre compte sera activé sous 24h après la confirmation du paiement.`,
      payment:"Paiement",
      paymentQuestion1: "Quels modes de paiement acceptez-vous ?",
      paymentAnswer1:`Nous acceptons les cartes de crédit, PayPal, ainsi que les paiements via Stripe et Payme pour les paiements annuels.`,
      paymentQuestion2: "Recevrai-je une facture après le paiement ?",
      paymentAnswer2:`Oui, une facture est automatiquement générée et disponible dans votre tableau de bord après chaque paiement.`,
      paymentQuestion3: "Comment fonctionne le paiement via Stripe et Payme ?",
      paymentAnswer3:`Nous utilisons Stripe et Payme pour garantir des paiements sécurisés. Vous pouvez choisir l'un de ces modes de paiement lors de l'abonnement, et le processus de paiement est simple et rapide.`,
      support:"Support",
      supportQuestion1: "Comment contacter le support client ?",
      supportAnswer1:`Vous pouvez nous contacter via le formulaire sur la page 'Contactez-nous' ou envoyer un e-mail à contact@hirecue.com.`,
      supportQuestion2: "Y a-t-il une documentation pour les nouveaux utilisateurs ?",
      supportAnswer2:`Oui, des tutoriels vidéo et une documentation complète sont disponibles pour vous aider à utiliser la plateforme.`,
      additional : ` supplémentaire `,
      additionalQuestion1 : ` Que sont les candidats ? `,
      additionalAnswer1:`Les candidats sont des personnes qui postulent à des offres d'emploi et participent à des évaluations sur la plateforme.`,
      additionalQuestion2 : ` Comment Hirecue protège-t-il mes données ? `,
      additionalAnswer2:`Hirecue utilise des protocoles de cryptage et de sécurité pour s'assurer que vos données sont protégées à toutes les étapes de leur utilisation.`,
      additionalQuestion3 : ` Quelle est la différence entre une évaluation et un test ? `,
      additionalAnswer3:`Un assessment évalue les capacités et les compétences globales du candidat, tandis qu'un test est une évaluation spécifique d'une compétence particulière.`,
      additionalQuestion4 : ` Hirecue est-il facile à utiliser ? `,
      additionalAnswer4:`Oui, Hirecue est conçu avec une interface conviviale qui permet aux recruteurs et aux candidats de naviguer facilement.`,
      additionalQuestion5 : ` Quel type d'assistance offrez-vous ? `,
      additionalAnswer5:`Nous offrons un support par email, ainsi qu'un support téléphonique pour les abonnés premium. En outre, nous disposons d'une FAQ et d'une base de connaissances complètes`,
      additionalQuestion6 : "J'ai d'autres questions ",
      additionalAnswer6:"Si vous avez d'autres questions, n'hésitez pas à contacter notre équipe d'assistance via la page 'Contactez-nous' ou à envoyer un e-mail à support@hirecue.com.",
      contacterNous:"Contactez-nous" ,
      envoiemail:"Envoyer un e-mail",
      choosePlan : "Choisissez votre plan ",
      choosePlanDesc : "Choisissez le plan qui correspond à vos besoins et commencez votre voyage dès aujourd'hui ! ",
      monthly:"Mensuel",
      annual:"Annuel",
      noJobsAvailable:"Aucune offre d'emploi disponible pour le moment.",
      errorFetchingJobs:"Erreur lors de la récupération des offres d'emploi.",
      loading:"Chargement en cours...",
    }
  }
};
export default translations;
