import React from "react";
/*  import { Link } from "react-router-dom"; */
import { Col, Container, Row } from "reactstrap";

const SectionP = () => {
  return (
    <React.Fragment>
      <section className="page-title-box">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="text-center text-white">
                <h3 className="mb-4">Politique de Cookies</h3>
                <div className="page-next">
                  {/* <nav
                    className="d-inline-block"
                    aria-label="breadcrumb text-center"
                  >
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Contact</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {" "}
                        Contact{" "}
                      </li>
                    </ol>
                  </nav> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> 
      <div className="position-relative" style={{ zIndex: 1 }}>
        <div className="shape">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250">
            <path
              fill="#FFFFFF"
              fillOpacity="1"
              d="M0,100L120,120C240,140,480,180,720,180C960,180,1200,140,1320,120L1440,100L1440,250L1320,250C1200,250,960,250,720,250C480,250,240,250,120,250L0,250Z"
            ></path>
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionP;
