import React from "react";
import { Container, Row, Col } from "reactstrap";
import CountUp from "react-countup";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <React.Fragment>
       <section className="section bg-light">
        <Container>
          <Row className="justify-content-center">
            <div className="section-title text-center">
              <h3 className="title mb-4 pb-2">
                See everything about your employee at one place.
              </h3>
              <p className="para-desc text-muted mx-auto">
                Start working with HireCue that can provide everything you need to
                generate awareness, drive traffic, connect.
              </p>
              <div className="mt-4">
                <Link to="/contact" className="btn btn-primary btn-hover mt-2">
                  <i className="uil uil-phone me-1"></i> Contact
                </Link>
                <Link
                  to="/faqs"
                  className="btn btn-outline-primary btn-hover ms-sm-1 mt-2"
                >
                  <i className="uil uil-file-question me-1"></i> Faq
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <section className="section">
        <Container>
          <div className="pricing-counter text-white">
            <Row>
              <Col lg={3} md={6}>
                <div className="counter-box mt-3">
                  <div className="counters counter_custom text-center">
                    <CountUp
                      end={15000}
                      duration={2}
                      className="counter counter_custom mb-0"
                    ></CountUp>
                    <h6 className="fs-16 mt-3">Available Jobs</h6>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="counter-box mt-3">
                  <div className="counters counter_custom text-center">
                    <CountUp
                      end={7500}
                      duration={2}
                      className="counter mb-0"
                    ></CountUp>
                    <h6 className="fs-16 mt-3">Applications</h6>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="counter-box mt-3">
                  <div className="counters counter_custom text-center">
                    <CountUp
                      end={8.85}
                      duration={2}
                      decimals={2}
                      className="counter mb-0"
                    ></CountUp>
                    <h6 className="fs-16 mt-3">Positive Feedback</h6>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="counter-box mt-3">
                  <div className="counters counter_custom text-center">
                    <CountUp
                      end={9875}
                      duration={2}
                      className="counter mb-0"
                    ></CountUp>
                    <h6 className="fs-16 mt-3">Members</h6>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Cta;
