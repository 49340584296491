import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import translations from "../../components/mulitlingue";
import "../Home/Layout3/Section.css";
import { Link } from "react-router-dom";
import pageAcceuil from "../../assets/images/pageAcceuil.png"; 

const Section = () => {
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    type: "",
    description: ""
  });

  const handleRequestDemoClick = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setSuccessMessage("");
    setErrorMessage("");
    resetForm();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      setSuccessMessage("Request submitted successfully!");
      setErrorMessage("");
      setTimeout(handleCloseModal, 5000);
    } else {
      setErrorMessage("Please fill in all fields.");
    }
  };

  const resetForm = () => {
    setFormData({
      email: "",
      phone: "",
      type: "",
      description: ""
    });
  };

  const isFormValid = () => {
    return formData.email && formData.phone && formData.type && formData.description;
  };

  return (
    <React.Fragment>
      <section className="custom-section" id="home" style={{ backgroundColor:"white"}}>
        {/* Main Content */}
        <Container className="mainbanner position-relative">
          <Row className="align-items-center justify-content-center text-center text-white">
            <Col lg={8}>
              <h1 className="display-3 fw-bolder mb-4 " >{lang.menu.NousTitle}</h1>
              <p className="fs-5 mb-4"  style={{ color: '#585858' }} >{lang.menu.NousDesc}</p>
       
            </Col>
          </Row> <br/>
        </Container>

      
      </section>
      <section className="custom-section2" id="home" style={{ backgroundImage: `url(${pageAcceuil})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <Link
                className="btn btn-primary me-2"
                onClick={handleRequestDemoClick}
                style={{ textTransform: 'uppercase', padding: '10px 30px', fontSize: '1.1rem' }}
              >
                {lang.menu.RequestDemo}
              </Link>
              <Link
                className="btn"
                to={`${process.env.REACT_APP_APP_DOMAIN}/login`}
                style={{
                  textTransform: 'uppercase',
                  backgroundColor: '#ff6600',
                  borderColor: '#ff6600',
                  color: 'white',
                  padding: '10px 30px',
                  fontSize: '1.1rem'
                }}
              >
                {lang.menu.inscriptionGratuite}
              </Link>
                {/* Request Demo Modal */}
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title className="fs-4">Request Demo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  name="type"
                  value={formData.type}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Demo Type</option>
                  <option value="General demo">General Demo</option>
                  <option value="Custom demo">Custom Demo</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Describe your request"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit} disabled={!isFormValid()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
     </section>   
    
    </React.Fragment>
  );
};

export default Section;