import React from "react";
import Section from "./Section";
import JobsListHirecue from "./JobsListHirecue";

const Jobs = () => {
  document.title = "Jobs | HireCue - Job Listing ";
  return (
    <React.Fragment>
      <Section />
      <JobsListHirecue/>
   
      <br/>
    </React.Fragment>
  );
};

export default Jobs;
