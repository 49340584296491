import React from "react";
import "./PolitiqueCookies.css";

const PolitiqueCookies = () => {
  return (
    <div className="politique-cookies-container">
      <h1>Politique de Cookies</h1>

      <p>
        Cette Politique de Cookies explique comment nous utilisons les cookies sur notre plateforme de recrutement. En utilisant notre site, vous acceptez l'utilisation de cookies conformément à cette politique. Si vous n'êtes pas d'accord avec l'utilisation des cookies, vous pouvez les désactiver ou modifier vos paramètres à tout moment.
      </p>

      <h2>1. Qu'est-ce qu'un cookie ?</h2>
      <p>
        Un cookie est un petit fichier de données qui est stocké sur votre appareil (ordinateur, téléphone mobile, tablette) lorsque vous visitez un site web. Les cookies nous permettent de vous offrir une expérience personnalisée, de suivre votre navigation, et de nous aider à analyser l'utilisation de notre site.
      </p>

      <h2>2. Pourquoi utilisons-nous des cookies ?</h2>
      <p>
        Nous utilisons des cookies pour diverses raisons, notamment pour améliorer votre expérience sur notre plateforme de recrutement, personnaliser le contenu, et analyser l'interaction avec notre site. Voici les types de cookies que nous utilisons :
      </p>

      <h3>Cookies nécessaires</h3>
      <p>
        Ces cookies sont essentiels pour le bon fonctionnement de notre site. Ils permettent des fonctions de base telles que la gestion de votre session, l'accès à des zones sécurisées du site, et l'enregistrement de vos préférences de langue. Ces cookies ne peuvent pas être désactivés.
      </p>

      <h3>Cookies de préférences</h3>
      <p>
        Ces cookies mémorisent vos préférences, telles que votre langue ou la région que vous avez choisie, pour personnaliser votre expérience sur notre site.
      </p>

      <h3>Cookies de performance et d'analyse</h3>
      <p>
        Ces cookies nous permettent d'analyser l'utilisation du site afin de comprendre comment les visiteurs interagissent avec notre plateforme. Cela nous aide à améliorer l'efficacité et la performance de notre site.
      </p>

      <h3>Cookies de publicité</h3>
      <p>
        Ces cookies sont utilisés pour afficher des publicités pertinentes en fonction de vos intérêts. Ils peuvent également être utilisés pour limiter le nombre de fois où vous voyez une publicité et pour mesurer l'efficacité de la campagne publicitaire.
      </p>

      <h2>3. Gestion des cookies</h2>
      <p>
        Vous avez la possibilité de gérer vos préférences en matière de cookies. Vous pouvez désactiver les cookies à tout moment en accédant aux paramètres de votre navigateur. Veuillez noter que si vous désactivez les cookies, certaines fonctionnalités de notre site peuvent ne pas fonctionner correctement.
      </p>
      <p>
        Pour modifier vos paramètres de cookies, consultez la section "Aide" ou "Paramètres" de votre navigateur. Voici comment vous pouvez gérer les cookies sur les principaux navigateurs :
      </p>
      <ul>
        <li><strong>Google Chrome :</strong> Allez dans "Paramètres" {'>'}"Confidentialité et sécurité" {'>'}"Cookies et autres données de site".</li>
        <li><strong>Mozilla Firefox :</strong> Allez dans "Options" {'>'}"Vie privée et sécurité" {'>'}"Cookies et données de site".</li>
        <li><strong>Safari :</strong> Allez dans "Préférences" {'>'}"Confidentialité" {'>'}"Cookies et données de site web".</li>
        <li><strong>Microsoft Edge :</strong> Allez dans "Paramètres" {'>'}"Confidentialité, recherche et services" {'>'}"Cookies".</li>
      </ul>

      <h2>4. Consentement à l'utilisation des cookies</h2>
      <p>
        En poursuivant votre navigation sur notre site, vous acceptez l'utilisation de cookies conformément à cette Politique de Cookies. Vous pouvez accepter ou refuser l'utilisation de cookies lorsque vous accédez à notre site pour la première fois, via la bannière de consentement des cookies.
      </p>

      <h2>5. Modifications de la Politique de Cookies</h2>
      <p>
        Nous nous réservons le droit de modifier cette politique de cookies à tout moment. Toute modification sera publiée sur cette page. Nous vous encourageons à consulter régulièrement cette page pour prendre connaissance de toute mise à jour.
      </p>

      <h2>6. Contact</h2>
      <p>
        Si vous avez des questions concernant notre Politique de Cookies, n'hésitez pas à nous contacter :
      </p>
      <ul>
        <li>Par e-mail : <strong>contact@hirecue.com</strong></li>
        <li>Par téléphone : <strong>+49 172 8056166</strong></li>
        <li>Par courrier : <strong>14 D, Rue Emir Abdelkader, Tunis 1002, Tunisie</strong></li>
      </ul>

      <p>Dernière mise à jour : 12-11-2024</p>
    </div>
  );
};

export default PolitiqueCookies;
