import React from "react";
import Section from '../RequestDemo/section';
import RequestDemoPage from "../RequestDemo/RequestDemoPage";

const RequestDemo = () => {
  document.title = "Contact | HireCue  ";
  return (
    <React.Fragment>
      <Section />
      <RequestDemoPage />
    </React.Fragment>
  );
};

export default RequestDemo;
