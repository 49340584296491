import React, { useEffect } from "react";

const MessengerChat = () => {
  useEffect(() => {
    // Load the Customer Chat SDK
    const loadFacebookCustomerChat = () => {
      if (document.getElementById("facebook-jssdk")) return;

      const js = document.createElement("script");
      js.id = "facebook-jssdk";
      js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
      js.async = true;
      js.defer = true;
      js.crossOrigin = "anonymous"; // Helps with CORS issues
      document.body.appendChild(js);

      js.onload = () => {
        // Initialize the Customer Chat Plugin
        window.fbAsyncInit = function () {
          window.FB.init({
            xfbml: true,
            version: "v15.0",
            appId: "505063619181068", // Your App ID
          });
        };
      };
    };

    loadFacebookCustomerChat();
  }, []);

  return (
    <div>
      {/* This is the Customer Chat Plugin */}
      <div id="fb-root"></div>
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id="493376487190267"
        minimized="true"
      ></div>
    </div>
  );
};

export default MessengerChat;
