import React, { useState } from "react";
import { Collapse, Card, CardBody, CardHeader } from "reactstrap"; // Utilisation des composants Card de reactstrap

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const renderAnswer = (answer) => {
    const stepPattern = /\d+\.\s?[^\n]+/g;
    const rolePattern = /-\s?\*\*([A-Za-z]+)\*\*\s?:\s([^\n]+)/g;

    const steps = answer.split("\n").filter(line => line.match(stepPattern));
    const roles = [];
    let match;
    while ((match = rolePattern.exec(answer)) !== null) {
      roles.push({ role: match[1], benefit: match[2] });
    }

    if (steps.length > 0 && roles.length > 0) {
      return (
        <div>
          <ol>
            {steps.map((step, index) => (
              <ol key={index}>{step}</ol>
            ))}
          </ol>
          <ul>
            {roles.map((item, index) => (
              <ol key={index}>
                <strong>{item.role}</strong>: {item.benefit}
              </ol>
            ))}
          </ul>
        </div>
      );
    }

    if (steps.length > 0) {
      return (
        <ol>
          {steps.map((step, index) => (
            <ol key={index}>{step}</ol>
          ))}
        </ol>
      );
    }

    if (roles.length > 0) {
      return (
        <ul>
          {roles.map((item, index) => (
            <ol key={index}>
              <strong>{item.role}</strong>: {item.benefit}
            </ol>
          ))}
        </ul>
      );
    }

    return <p>{answer}</p>;
  };

  return (
    <Card className="faq-item">
      <CardHeader
        onClick={toggle}
        className="faq-question"
        style={{ cursor: "pointer", backgroundColor: "#f7f7f7" }}
      >
        {question}
        <i className={`uil ${isOpen ? "uil-angle-up" : "uil-angle-down"}`} style={{ float: "right" }} />
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          {renderAnswer(answer)}
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default FAQItem;
