import React from "react";
import Section from "../../Company/Pricing/Section";
import PricingPage from "../../Company/Pricing/PricingPage";
import Cta from "../../Company/Pricing/Cta";
import FeatureTable from "../../Company/Pricing/FeatureTable";
import FaqContent from "../../Company/Faqs/FaqContent";

const Pricing = () => {
  document.title = "Pricing | HireCue  ";
  return (
    <React.Fragment>
      <Section />
      <PricingPage/>
      <FeatureTable/>   
      <FaqContent />
      {/* <Cta /> */}
      <br/>
    </React.Fragment>
  );
};

export default Pricing;
