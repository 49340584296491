import React from "react";
import Section from "../whyjobvia/section";
import WhyjobviaPage from "../whyjobvia/whyjobviaPage";
import Services from "../whyjobvia/Services";

const whyHirecue = () => {
  document.title = "Team | HireCue  "
  return (
    <React.Fragment>
      <Section />
      <Services />

      {/* <WhyjobviaPage/> */}
    </React.Fragment>
  );
};

export default whyHirecue;