import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody ,Button} from "reactstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./pricing.css";
import axios from "axios";
import "./App.css";
import { MdHelpOutline } from 'react-icons/md';
import translations from "../../../components/mulitlingue";

const EXCHANGE_RATES = {
  EUR: 1.00,
  USD: 1.09,
  TND: 2.00,
  GBP: 0.85,
};


const PricingPage = () => {
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [selectedCurrency, setSelectedCurrency] = useState("EUR");
  async function detectCurrency() {
      try {
          // Essayer avec une API
          const response = await fetch('https://api.ipgeolocation.io/ipgeo?apiKey=9e3b45451ec84066bbe6ab79f68749c1');
          if (!response.ok) {
              throw new Error('API indisponible');
          }
  
          const data = await response.json();
          return data.currency ? data.currency.code : null;
      } catch {
          // Fallback sur les paramètres du navigateur
          console.warn('API indisponible, fallback sur la locale');
          return detectCurrencyByLocale();
      }
  }
  
  function detectCurrencyByLocale() {
      const language = navigator.language || navigator.userLanguage;
      const currencyMapping = {
          'fr-FR': 'EUR',
          'fr-TN': 'TND',
          'en-US': 'USD',
      };
      return currencyMapping[language] || 'USD';
  }
  

  
  async function getUserLocation() {
    
    return new Promise((resolve, reject) => {
      var latitude;
      var longitude;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    resolve({ latitude, longitude });
                },
                error => {
                    console.error('Error getting geolocation:', error);
                    resolve({ latitude, longitude });
                }
            );
        } else {
            console.error('Geolocation is not available');
            resolve({ latitude, longitude });
        }
    });
}
const saveDeviceFeatures = async () => {
  try {
    const deviceType = window.innerWidth < 480;
    const os = navigator.platform;

    const { latitude, longitude } = await getUserLocation();

    let city;
    let country;
    let currencyLocation = "EUR"; 

    if (latitude && longitude) {
      console.log("latitude:", latitude, "longitude:", longitude);

      // Call reverse geocoding API
      const location = await axios.get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
      );

      city = location.data.city;
      country = location.data.countryName;

      if (country) {
        if (country.toLowerCase() === "tunisia") {
          currencyLocation = "TND";
        } else if ([
          "austria", "belgium", "france", "germany", "italy", "spain", "netherlands",
          "portugal", "ireland", "greece" 
        ].includes(country.toLowerCase())) {
          currencyLocation = "EUR";
        } else if (["united states", "canada", "mexico"].includes(country.toLowerCase())) {
          currencyLocation = "USD";
        }
      }
    }

    const body = { city, country, deviceType, os, currencyLocation };
    console.log("body:", body);
    setSelectedCurrency(currencyLocation)
  } catch (err) {
    console.error(err);
  }
};

  const [selectedBilling, setSelectedBilling] = useState(lang.menu.monthly);
  const [subscriptionType, setSubscriptionType] = useState([]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [subscriptionfeaturespertypes, setSubscriptionfeaturespertypes] = useState([]);
  const [activeView, setActiveView] = useState("entreprise"); 

  useEffect(() => {
    detectCurrency().then(currency => {
      console.log("Detected currency:", currency);
      setSelectedCurrency(currency);
    });
    
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionType/getAll`);
        setSubscriptionType(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypes`);
        setSubscriptionfeaturespertypes(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const calculateConvertedPrice = (price, currency) => {
    if (!price) return "0.00"; 
  
    const cleanedPrice = parseFloat(
      price.replace(/[^\d.,]/g, "").replace(",", ".")
    );

    if (isNaN(cleanedPrice)) return "0.00"; // Si la conversion échoue, retourne 0

    const rate = selectedBilling === lang.menu.monthly ? 1 : 12;

    return (cleanedPrice * EXCHANGE_RATES[currency] * rate).toFixed(2);
  }; 
   const calculateConvertedPricereduction = (price, currency) => {
    if (!price) return "0.00"; 
  
    let cleanedPrice = parseFloat(
      price.replace(/[^\d.,]/g, "").replace(",", ".")
    );

    if (isNaN(cleanedPrice)) return "0.00"; // Si la conversion échoue, retourne 0
    cleanedPrice=cleanedPrice/0.6
    const rate = selectedBilling === lang.menu.monthly ? 1 : 12;

    return (cleanedPrice * EXCHANGE_RATES[currency] * rate).toFixed(2);
  };
  
const subscriptionLinks = {
  Free: "https://buy.stripe.com/test_cN2eYt5Gi4tl8LufZ5",
  "Solo Starter": "https://buy.stripe.com/test_6oE4jP7Oq8JBaTCbIJ",
  "Solo Pro": "https://buy.stripe.com/test_8wMaId4Ce9NF9Py7su",
  "Solo Premium": "https://buy.stripe.com/test_6oE7w10lY4tle5OeUX",
  "Entreprise Starter": "https://buy.stripe.com/test_28o4jP4Ce7Fx8Lu9AE",
  "Entreprise Pro": "https://buy.stripe.com/test_9AQbMhd8K8JBgdW7sx",
  "Entreprise Premium": "https://buy.stripe.com/test_14k6rX1q23phf9SbIO",
  "Pay as you go": "https://buy.stripe.com/test_bIYdUp0lYf7Z3ra144"
};

  const PurchaseLink = ({ subscriptionType }) => {
    const link = subscriptionLinks[subscriptionType];
    if (!link) return null;
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" className="btn-soft-primary rounded-pill">
        Purchase Now <i className="uil uil-arrow-right"></i>
      </a>
    );
  };
  const filteredSubscriptionType = subscriptionType.filter(subscription => {
    if (activeView === "solo") {
        return subscription.Type.toLowerCase().includes("solo") || 
               subscription.Type.toLowerCase() === "free" || 
               subscription.Type.toLowerCase() === "pay as you go";
    } else if (activeView === "entreprise") {
        return subscription.Type.toLowerCase().includes("entreprise") || 
               subscription.Type.toLowerCase() === "free" || 
               subscription.Type.toLowerCase() === "pay as you go";
    }
    return false;
});

  return (
    <React.Fragment>
      <section className="section py-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center">
                <h1 className="badge bg-warning-subtle text-warning fs-15 mb-2">
                  {lang.menu.choosePlan}
                </h1>
                <p className="text-muted">
                {lang.menu.choosePlanDesc}
                </p>
              </div>
            </Col>
          </Row>
        {/*   <div className="text-center mt-3">
            <button
              className={`btn btn-outline-secondary me-2 ${selectedCurrency === "EUR" ? "active" : ""}`}
              onClick={() => setSelectedCurrency("EUR")}
            >
              EUR
            </button>
            <button
              className={`btn btn-outline-secondary me-2 ${selectedCurrency === "USD" ? "active" : ""}`}
              onClick={() => setSelectedCurrency("USD")}
            >
              USD
            </button>
            <button
              className={`btn btn-outline-secondary me-2 ${selectedCurrency === "TND" ? "active" : ""}`}
              onClick={() => setSelectedCurrency("TND")}
            >
              TND
            </button>
            <button
              className={`btn btn-outline-secondary ${selectedCurrency === "GBP" ? "active" : ""}`}
              onClick={() => setSelectedCurrency("GBP")}
            >
              GBP
            </button>
          </div> */}

          <div className="text-center mt-3">
            <label className={`lunar-solar-switch ${selectedBilling === lang.menu.annual ? "active" : ""}`}>
              <input
                type="checkbox"
                className="visually-hidden"
                checked={selectedBilling === lang.menu.annual}
                onChange={() => setSelectedBilling(selectedBilling === lang.menu.monthly ? lang.menu.annual : lang.menu.monthly)}
              />
              <span className="lunar-solar-label">
                {selectedBilling === lang.menu.monthly ? lang.menu.monthly : lang.menu.annual}
              </span>
            </label>
          </div>
          <div className="text-center mt-3">
          <Button 
    color="primary" 
    className={`lunarSwitcher ${activeView === "entreprise" ? "active" : ""}`} 
    onClick={() => setActiveView("entreprise")}
>
    Entreprise
</Button>

<Button 
    color="primary" 
    className={`lunarSwitcher ${activeView === "solo" ? "active" : ""}`} 
    onClick={() => setActiveView("solo")}
>
    Solo
</Button>

</div>



          <Row className="pricing-grid mt-5">
    {filteredSubscriptionType.map((subscription) => (
        <Col lg={4} md={6} className="mt-4" key={subscription.id}>
<Card className="pricing-box bg-white shadow-sm h-100 rounded-3 position-relative">
  {/* Promotion Badge */}
  <div className="badge-promo bg-danger text-white position-absolute">
    -{subscription.promoPercentage}%
  </div>

  <CardBody className="p-4 px-lg-5">
    {/* Icon */}
    <div className="pricing-icon bg-light rounded-circle icons-md mx-auto">
      <Icon icon="uim-telegram-alt" className="text-primary" />
    </div>

    {/* Plan Name */}
    
    <h4 className="text-center text-dark mt-4">    {subscription.Type === "Pay as you go" ? "Customized offer" : subscription.Type}</h4>

    {/* Pricing Section */}
    <div className="text-center mt-3">
      {/* Prix avant réduction */}
      <h4 className="text-muted fw-light text-decoration-line-through">
      {calculateConvertedPricereduction(subscription.MonthlyPrice, selectedCurrency)}  {selectedCurrency} 
      </h4>

      {/* Prix après réduction */}
      <h2 className="text-primary fw-bold">
        
      {calculateConvertedPrice(subscription.MonthlyPrice, selectedCurrency)}
    {" "}
        <small className="fs-16 text-muted"> {selectedCurrency} / {selectedBilling === lang.menu.monthly ? "mo" : "yr"}</small>
      </h2>

      <p className="text-muted mt-2">
        -{subscription.promoPercentage}% {lang.menu.promotion40}
      </p>
    </div>

    {/* Features */}
    <ul className="list-unstyled mt-4">
      {subscriptionfeaturespertypes
        .filter((feature) => feature.type_id === subscription.id && feature.feature_available === 1 && feature.feature_quota_max !== null)
        .map((feature) => (
          <li key={feature.feature_id} className="mb-2 d-flex align-items-center">
            <i className="mdi mdi-check-circle-outline text-success me-2"></i>
            <span>{feature.feature_quota_max} {feature.feature_name}</span>
          </li>
        ))}
    </ul>

    {/* Button */}
    <div className="text-center mt-4">
      <button className="btn btn-primary px-4 py-2 rounded-pill">Purchase Now</button>
    </div>
  </CardBody>
</Card>



        </Col>
    ))}
</Row>

        </Container>
      </section>
    </React.Fragment>
  );
};

export default PricingPage;
