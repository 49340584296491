import React, { useState } from "react";
import { Container,Card, Button, Row, Col } from "react-bootstrap"; 
import translations from "../../components/mulitlingue";

const Services = () => {
    const [lang, setLang] = useState(() => {
        const storedLang = localStorage.getItem("selectedLang");
        if (storedLang) {
          const parsedLang = JSON.parse(storedLang);
          if (parsedLang.language === "Français") {
            return translations.fr;
          }
        }
        return translations.en;
      });
  return (
    <React.Fragment>
         <section className="section">
         <Container>
        
        {/* Section 1: Solutions pour les recruteurs */}
        <section className="section-title  recruteurs">
        <h4 className="title text-center">{lang.menu.organisationetService} </h4>

        <h2 className="text-primary mb-4">{lang.menu.solRecTitle}</h2>
          <span style={{ color: "#333", fontSize: "14px" }}>{lang.menu.solRecDesc}</span>
          
          <Row>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <h5 style={{ color: "#ff6600", fontWeight: "bold" }}>{lang.menu.solRec1}</h5>
                  <span style={{ color: "#333", fontSize: "14px" }}>{lang.menu.solRec1Desc}</span>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <h5 style={{ color: "#ff6600", fontWeight: "bold" }}>{lang.menu.solRec2}</h5>
                  <span style={{ color: "#333", fontSize: "14px" }}>{lang.menu.solRec2Desc}</span>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <h5 style={{ color: "#ff6600", fontWeight: "bold" }}>{lang.menu.solRec3}</h5>
                  <span style={{ color: "#333", fontSize: "14px" }}>{lang.menu.solRec3Desc}</span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Section 2: Solutions pour les candidats */}
        <section className="section-title  candidats my-5">
        <h2 className="text-primary mb-4">{lang.menu.solCanTitle}</h2>
          <span style={{ color: "#333", fontSize: "14px" }}>{lang.menu.solCanDesc}</span>
          
          <Row>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <h5 style={{ color: "#ff6600", fontWeight: "bold" }}>{lang.menu.solCan1}</h5>
                  <span style={{ color: "#333", fontSize: "14px" }}>{lang.menu.solCan1Desc}</span>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <h5 style={{ color: "#ff6600", fontWeight: "bold" }}>{lang.menu.solCan2}</h5>
                  <span style={{ color: "#333", fontSize: "14px" }}>{lang.menu.solCan2Desc}</span>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <h5 style={{ color: "#ff6600", fontWeight: "bold" }}>{lang.menu.solCan3}</h5>
                  <span style={{ color: "#333", fontSize: "14px" }}>{lang.menu.solCan3Desc}</span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Section 3: Produits phares */}
        <section className="section-title  my-5">
        <h2 className="text-primary mb-4">{lang.menu.ProPhare}</h2>
          <Row>
            <Col md={6}>
              <Card>
              <Card.Body>
  <h5 style={{ color: "#ff6600", fontWeight: "bold" }}>HireCueMatch</h5>
  <span style={{ color: "#333", fontSize: "14px" }}>{lang.menu.HireCueMatch}</span>
</Card.Body>

              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <h5 style={{ color: "#ff6600", fontWeight: "bold" }}>HireCueTests</h5>
                  <span style={{ color: "#333", fontSize: "14px" }}>{lang.menu.HireCueTests}</span>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <h5 style={{ color: "#ff6600", fontWeight: "bold" }}>HireCueDashboard</h5>
                  <span style={{ color: "#333", fontSize: "14px" }}>{lang.menu.HireCueDashboard}</span>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <h5 style={{ color: "#ff6600", fontWeight: "bold" }}>HireCueBoost</h5>
                  <span style={{ color: "#333", fontSize: "14px" }}>{lang.menu.HireCueBoost}</span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Section 4: Conclusion */}
        <section className="conclusion text-center my-5">
          <h3>{lang.menu.serviceFooter}</h3>
        </section>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Services;
