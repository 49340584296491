import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody ,Button} from "reactstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./pricing.css";
import axios from "axios";
import "./App.css";
import { MdHelpOutline } from 'react-icons/md';
import translations from "../../../components/mulitlingue";

const EXCHANGE_RATES = {
  EUR: 1.00,
  USD: 1.09,
  TND: 2.00,
  GBP: 0.85,
};


const PricingPage = () => {
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [selectedCurrency, setSelectedCurrency] = useState("EUR");
  const [selectedBilling, setSelectedBilling] = useState(lang.menu.monthly);
  const [subscriptionType, setSubscriptionType] = useState([]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [subscriptionfeaturespertypes, setSubscriptionfeaturespertypes] = useState([]);
  const [activeView, setActiveView] = useState("entreprise"); 

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionType/getAll`);
        setSubscriptionType(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypes`);
        console.log("2:",response.data)
        setSubscriptionfeaturespertypes(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const calculateConvertedPrice = (price, currency) => {
    const rate = selectedBilling === lang.menu.monthly ? 1 : 12;
    return (parseFloat(price.slice(1)) * EXCHANGE_RATES[currency] * rate).toFixed(2);
  };

  const subscriptionLinks = {
    Free: `${process.env.REACT_APP_APP_DOMAIN}/page-register`,
    "Solo Starter": `${process.env.REACT_APP_APP_DOMAIN}/page-register`,
    "Solo Pro": `${process.env.REACT_APP_APP_DOMAIN}/page-register`,
    "Solo Premium": `${process.env.REACT_APP_APP_DOMAIN}/page-register`,
    "Entreprise Starter": `${process.env.REACT_APP_APP_DOMAIN}/page-register`,
    "Entreprise Pro": `${process.env.REACT_APP_APP_DOMAIN}/page-register`,
    "Entreprise Premium": `${process.env.REACT_APP_APP_DOMAIN}/page-register`,
    "Pay as you go": `${process.env.REACT_APP_APP_DOMAIN}/page-register`,
  };

  const PurchaseLink = ({ subscriptionType }) => {
    const link = subscriptionLinks[subscriptionType];
    if (!link) return null;
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" className="btn-soft-primary rounded-pill">
        Purchase Now <i className="uil uil-arrow-right"></i>
      </a>
    );
  };
  const filteredSubscriptionType = subscriptionType.filter(subscription => {
    if (activeView === "solo") {
        return subscription.Type.toLowerCase().includes("solo") || 
               subscription.Type.toLowerCase() === "free" || 
               subscription.Type.toLowerCase() === "pay as you go";
    } else if (activeView === "entreprise") {
        return subscription.Type.toLowerCase().includes("entreprise") || 
               subscription.Type.toLowerCase() === "free" || 
               subscription.Type.toLowerCase() === "pay as you go";
    }
    return false;
});

  return (
    <React.Fragment>
      <section className="section py-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center">
                <h1 className="badge bg-warning-subtle text-warning fs-15 mb-2">
                  {lang.menu.choosePlan}
                </h1>
                <p className="text-muted">
                {lang.menu.choosePlanDesc}
                </p>
              </div>
            </Col>
          </Row>
          <div className="text-center mt-3">
            <button
              className={`btn btn-outline-secondary me-2 ${selectedCurrency === "EUR" ? "active" : ""}`}
              onClick={() => setSelectedCurrency("EUR")}
            >
              EUR
            </button>
            <button
              className={`btn btn-outline-secondary me-2 ${selectedCurrency === "USD" ? "active" : ""}`}
              onClick={() => setSelectedCurrency("USD")}
            >
              USD
            </button>
            <button
              className={`btn btn-outline-secondary me-2 ${selectedCurrency === "TND" ? "active" : ""}`}
              onClick={() => setSelectedCurrency("TND")}
            >
              TND
            </button>
            <button
              className={`btn btn-outline-secondary ${selectedCurrency === "GBP" ? "active" : ""}`}
              onClick={() => setSelectedCurrency("GBP")}
            >
              GBP
            </button>
          </div>

          <div className="text-center mt-3">
            <label className={`lunar-solar-switch ${selectedBilling === lang.menu.annual ? "active" : ""}`}>
              <input
                type="checkbox"
                className="visually-hidden"
                checked={selectedBilling === lang.menu.annual}
                onChange={() => setSelectedBilling(selectedBilling === lang.menu.monthly ? lang.menu.annual : lang.menu.monthly)}
              />
              <span className="lunar-solar-label">
                {selectedBilling === lang.menu.monthly ? lang.menu.monthly : lang.menu.annual}
              </span>
            </label>
          </div>
          <div className="text-center mt-3">
          <Button 
    color="primary" 
    className={`lunarSwitcher ${activeView === "entreprise" ? "active" : ""}`} 
    onClick={() => setActiveView("entreprise")}
>
    Entreprise
</Button>

<Button 
    color="primary" 
    className={`lunarSwitcher ${activeView === "solo" ? "active" : ""}`} 
    onClick={() => setActiveView("solo")}
>
    Solo
</Button>

</div>

          <Row className="pricing-grid mt-5">
    {filteredSubscriptionType.map((subscription) => (
        <Col lg={4} md={6} className="mt-4" key={subscription.id}>
            <Card className="pricing-box bg-light shadow-sm h-100 rounded-3">
                <CardBody className="p-4 px-lg-5">
                    <div className="pricing-icon bg-light rounded-circle icons-md">
                        <Icon icon="uim-telegram-alt" className="text-primary" />
                    </div>
                    <div className="pricing-name text-center mt-4">
                        <h4 className="fs-18 text-dark">{subscription.Type}</h4>
                    </div>
                    <div className="pricing-price text-center mt-4">
                        <h2 className="fw-semibold">
                            {calculateConvertedPrice(subscription.MonthlyPrice, selectedCurrency)}{" "}
                            <small className="fs-16">{selectedCurrency} {selectedBilling === lang.menu.monthly ? "mo" : "yr"}</small>
                        </h2>
                    </div>

                    <ul className="list-unstyled pricing-details text-muted mt-4">
                        {subscriptionfeaturespertypes
                            .filter(
                                (feature) => feature.type_id === subscription.id && 
                                             feature.feature_available === 1 && 
                                             feature.feature_quota_max !== null
                            )
                            .map((feature) => (
                                <li key={feature.feature_id}>
                                    <i className="mdi mdi-check-bold bg-success-subtle text-success me-2"></i>
                                    {feature.feature_quota_max}{" "}
                                    {feature.feature_name}{" "}
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{feature.feature_description}</Tooltip>}
                                    >
                                        <span style={{ cursor: 'pointer' }}>
                                            <MdHelpOutline />
                                        </span>
                                    </OverlayTrigger>
                                </li>
                            ))
                        }
                    </ul>

                    <div className="text-center mt-4 mb-2">
                        <PurchaseLink subscriptionType={subscription.Type} />
                    </div>
                </CardBody>
            </Card>
        </Col>
    ))}
</Row>

        </Container>
      </section>
    </React.Fragment>
  );
};

export default PricingPage;
