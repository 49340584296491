import React ,{useState}from "react";
import { Form } from "react-bootstrap";
import { Col, Container, Row, Input, Label,Alert ,Button} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2"; 

//Import Images
import contactImage from "../../assets/images/contact.png";

const ContactContent = () => {
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    type: "",
    description: "",
  });
  const resetForm = () => {
    setFormData({
      email: "",
      phone: "",
      type: "",
      description: "",
    });
  };
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/requestDemo/createRequestDemo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Request submitted successfully!',
            showConfirmButton: false,
            timer: 1500
          });
        } else {
      

          throw Swal.fire({
            icon: 'error',
            title: "Request failed",
            showConfirmButton: false,
            timer: 1500
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: "Failed to submit request. Please try again.",
          showConfirmButton: false,
          timer: 1500
        });
      })
      .finally(() => {
        setTimeout(() => {
        }, 10000); 
      });
  };
  const isFormValid = () => {
    return (
      formData.email.trim() !== "" &&
      formData.phone.trim() !== "" &&
      formData.type.trim() !== "" &&
      formData.description.trim() !== ""
    );
  };
  return (
    <React.Fragment>

      <section className="section">
        <Container>
          <Row className="align-items-center mt-5">
            <Col lg={6}>
              <div className="section-title mt-4 mt-lg-0">
                <h3 className="title">Get in touch</h3>
                <p className="text-muted">
                  Start working with HireCue that can provide everything you need
                  to generate awareness, drive traffic, connect.
                </p>
                <Form>
                   {/* Success  */}
     
              <Form>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formPhone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formType">
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="General demo">General demo</option>
                    <option value="Custom demo">Custom demo</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="formDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter Description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <br/>
                <Button variant="primary" onClick={handleSubmit} disabled={!isFormValid()}>
                Submit
              </Button>
              </Form>
            </Form>
              </div>
            </Col>
            <Col lg={5} className="ms-auto order-first order-lg-last">
              <div className="text-center">
                <img src={contactImage} alt="" className="img-fluid" />
              </div>
              <div className="mt-4 pt-3">
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-map-marker"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">
                
                    14 D, Rue Emir Abdelkader, Tunis 1002, Tunisie                   </p>
                  </div>
                </div>
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-envelope"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">contact@hirecue.com</p>
                  </div>
                </div>
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-phone-alt"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">+49 172 8056166</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="map">
        <iframe
          title="maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15958.144016641977!2d10.187634018998624!3d36.81781438643767!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd353c254e8c65%3A0x4fb07d75090e420d!2s14%20Rue%20Emir%20Abdelkader%2C%20Tunis%201002%2C%20Tunisie!5e0!3m2!1sfr!2stn!4v1699654138913!5m2!1sfr!2stn"
          height="350"
          style={{ border: `0`, width: `100%` }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>

    </React.Fragment>
  );
};

export default ContactContent;
