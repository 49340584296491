import React, { useState } from "react";
import "./PolitiqueCookies.css";
import translations from "../../components/mulitlingue";

const PolitiqueCookies = () => {
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  return (
    <div className="politique-cookies-container">
    <h1>{lang.menu.politiqueCookies}</h1>

    <p>{lang.menu.explanation}</p>

    <h2> {lang.menu.whatIsACookie}</h2>
    <p>{lang.menu.cookieDescription}</p>

    <h2>{lang.menu.whyCookies}</h2>
    <p>{lang.menu.cookiePurpose}</p>

    <h3>{lang.menu.necessaryCookies}</h3>
    <p>{lang.menu.necessaryCookiesDescription}</p>

    <h3>{lang.menu.preferenceCookies}</h3>
    <p>{lang.menu.preferenceCookiesDescription}</p>

    <h3>{lang.menu.performanceCookies}</h3>
    <p>{lang.menu.performanceCookiesDescription}</p>

    <h3>{lang.menu.advertisingCookies}</h3>
    <p>{lang.menu.advertisingCookiesDescription}</p>

    <h2>{lang.menu.manageCookies}</h2>
    <p>{lang.menu.manageCookiesDescription}</p>
    <p>{lang.menu.browserSettings}</p>
    <ul>
      <li><strong>{lang.menu.googleChrome} :</strong> {lang.menu.chromeSettings}</li>
      <li><strong>{lang.menu.mozillaFirefox} :</strong> {lang.menu.firefoxSettings}</li>
      <li><strong>{lang.menu.safari} :</strong> {lang.menu.safariSettings}</li>
      <li><strong>{lang.menu.microsoftEdge} :</strong> {lang.menu.edgeSettings}</li>
    </ul>

    <h2>{lang.menu.cookieConsent}</h2>
    <p>{lang.menu.cookieConsentDescription}</p>

    <h2> {lang.menu.cookiePolicyChanges}</h2>
    <p>{lang.menu.cookiePolicyChangesDescription}</p>

    <h2> {lang.menu.contact}</h2>
    <p>{lang.menu.contactDescription}</p>
    <ul>
      <li>{lang.menu.contactEmail} <strong>contact@hirecue.com</strong></li>
      <li>{lang.menu.contactPhone} <strong>+49 172 8056166</strong></li>
      <li>{lang.menu.contactAddress} :<strong>14 D, Rue Emir Abdelkader, Tunis 1002, Tunisie</strong></li>
    </ul>

    <p>{lang.menu.lastUpdated}: 12-11-2024</p>
  </div>
  );
};

export default PolitiqueCookies;
