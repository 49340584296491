import React, { useState, useEffect } from "react";
import { Col, Row, Modal, ModalBody, ModalHeader, Label, Badge, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import axios from 'axios';
import translations from "../../../components/mulitlingue";
import { FaBriefcase, FaMoneyBill, FaMapMarkerAlt, FaRegClock } from "react-icons/fa"; 

const Offres = () => {
  const [modal, setModal] = useState(false);
  const [jobListData, setJobListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [visibleJobsCount, setVisibleJobsCount] = useState(3);
  const [selectedJob, setSelectedJob] = useState(null); 

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  const fetchJobs = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const jobResponse = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`);
      const jobs = jobResponse.data;
      const filteredJobs = jobs.filter(job => job.companyid !== 1 && job.archived === 0);
      setJobListData(filteredJobs);
    } catch (error) {
      console.error('Erreur lors de la récupération des emplois:', error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const [companies, setCompanies] = useState([]);
  
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`);
        const dataCmp = await response.json();
        setCompanies(dataCmp);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, []);

  const getCompanyLogo = (companyId) => {
    const company = companies.find((comp) => comp.companyid === companyId);
    if (!company) {
      console.warn(`Company with id ${companyId} not found.`);
      return null; 
    }
    let pathImage = `${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}`;
    return pathImage;
  };
  const openModal = (job) => {
    if (!job) {
      console.warn("Job is undefined or null");
      return;
    }
    setSelectedJob(job);
    setModal(true);
  };
  

  const closeModal = () => {
    setModal(false); 
  };
  return (
    <React.Fragment>
      <style>
        {`
          .two-line-truncate {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
          }
        `}
      </style>

      {isLoading && (
        <div className="text-center mt-5">
          <Spinner color="primary" />
          <p>{lang.menu.loading}</p>
        </div>
      )}

      {!isLoading && hasError && (
        <div className="text-center mt-5">
          <p>{lang.menu.errorFetchingJobs}</p>
        </div>
      )}

      {!isLoading && !hasError && jobListData.length === 0 && (
        <div className="text-center mt-5">
          <p>{lang.menu.noJobsAvailable}</p>
        </div>
      )}

{!isLoading && !hasError && jobListData.length > 0 && (
        jobListData.slice(0, visibleJobsCount).map((job, key) => (

          <div
          key={job.id}
          className="job-box card mt-4"
          style={{ maxWidth: '80%', margin: '10px auto' }}
        >
        
            <div className="p-4">
              <Row className="align-items-center">
                <Col md={2}>
                  <div className="text-center mb-4 mb-md-0">
                    <img
                      src={getCompanyLogo(job.companyid)}
                      alt=""
                      className="img-fluid rounded-3"
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-2 mb-md-0">
                    <h5 className="fs-18 mb-1">
                    <Link to="#" onClick={() => openModal(job)}>{job.JobTitle}</Link>
                      
                    </h5>
                    <p className="text-muted fs-14 mb-0">
                      {job.Country}
                    </p>
                  </div>
                </Col>
                <Col md={3}>
                  <p className="text-muted mb-0 two-line-truncate">
                    {job.JobDescription}
                  </p>
                </Col>
                <Col md={2}>
                  <p className="text-muted mb-2">
                    {job.OfferedSalary} <span className="text-primary">TND</span>
                  </p>
                </Col>
                <Col md={2}>
                  <span className={
                    job.JobType === "Full Time"
                      ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                      : "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                  }>
                    {job.JobType}
                  </span>
                </Col>
              </Row>
            </div>
            <div className="p-3 bg-light">
              <Row>
                <Col md={4}>
                  <p className="text-muted mb-0">
                    <span className="text-dark">{lang.menu.Experience}:</span> {job.Experience}
                  </p>
                </Col>
                <Col md={5}>
                <div>
                  <p className="text-muted mb-0">
                    <span className="text-dark">
                      {job.skills ? `${lang.menu.Skills}:` : ""}
                    </span>
                    {job.skills && typeof job.skills === "string" ? (
  job.skills
    .replace("[", "")
    .replace("]", "")
    .split("),")
    .map((skill, index) => {
      const skillParts = skill
        .replace("('", "")
        .replace("')", "")
        .split("',");
      return (
        <Badge key={index} variant="warning" style={{ margin: "1%" }}>
          <span style={{ textAlign: "center" }}>{skillParts[0]}</span>
        </Badge>
      );
    })
) : (
  <p>{lang.menu.noSkillsAvailable || "No skills available"}</p>
)}
                  </p>
                </div>
              </Col>
              <Col md={3}>
                  <Link
                    to={`${process.env.REACT_APP_APP_DOMAIN}/details/${job.id}`}
                    onClick={openModal}
                    className="primary-link"
                  >
                    {lang.menu.ApplyNow} <i className="mdi mdi-chevron-double-right"></i>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        ))
      )}

{!isLoading && !hasError && jobListData.length > visibleJobsCount && (
        <div className="text-center mt-4">
          <button
            className="btn btn-primary"
            onClick={() => setVisibleJobsCount(prevCount => prevCount + 3)}
          >
            {lang.menu.viewMore || "View More"}
          </button>
        </div>
      )}
 <Modal isOpen={modal} toggle={closeModal} centered>
      <ModalHeader toggle={closeModal} className="bg-primary text-white">
        <h5 className="modal-title">
          <strong>{selectedJob?.JobTitle}</strong>
        </h5>
      </ModalHeader>
      <ModalBody>
        {selectedJob ? (
          <div className="job-details-modal">
            <div className="modal-info mb-3">
              <p>
                <strong>{lang.menu.Description}: </strong>{selectedJob.JobDescription}
              </p>
            </div>

            <div className="modal-info mb-3">
              <FaMoneyBill size={20} className="me-2 text-primary" />
              <strong>{lang.menu.OfferedSalary}: </strong>{selectedJob.OfferedSalary}
            </div>

            <div className="modal-info mb-3">
              <FaMapMarkerAlt size={20} className="me-2 text-primary" />
              <strong>{lang.menu.Country}: </strong>{selectedJob.Country}
            </div>

            <div className="modal-info mb-3">
              <FaRegClock size={20} className="me-2 text-primary" />
              <strong>{lang.menu.Experience}: </strong>{selectedJob.Experience}
            </div>

            <div className="modal-info mb-3">
              <strong>{lang.menu.Skills}: </strong>
              <div className="skills-list">
              {selectedJob.skills && typeof selectedJob.skills === "string" ? (
  selectedJob.skills
    .replace("[", "")
    .replace("]", "")
    .split("),")
    .map((skill, index) => {
      const skillParts = skill
        .replace("('", "")
        .replace("')", "")
        .split("',");
      return (
        <Badge key={index} variant="warning" style={{ margin: "1%" }}>
          <span style={{ textAlign: "center" }}>{skillParts[0]}</span>
        </Badge>
      );
    })
) : (
  <p>{lang.menu.noSkillsAvailable || "No skills available"}</p>
)}

              </div>
            </div>

            <div className="modal-info">
              <FaBriefcase size={20} className="me-2 text-primary" />
              <strong>{lang.menu.JobType}: </strong>{selectedJob.JobType}
            </div>
          </div>
        ) : (
          <div className="text-center">
            <Spinner color="primary" />
            <p>{lang.menu.loading}</p>
          </div>
        )}
      </ModalBody>
    </Modal>
    </React.Fragment>
  );
};

export default Offres;
