
import React, { useEffect } from "react";

const MessengerChat = () => {
  useEffect(() => {
    const initFacebookSDK = () => {
      if (window.FB) {
        window.FB.init({
          appId: "505063619181068",
          autoLogAppEvents: true,
          xfbml: true,
          version: "v15.0",
        });
        window.FB.XFBML.parse();
      } else {
        setTimeout(initFacebookSDK, 1000);
      }
    };
    initFacebookSDK();
  }, []);

  return (
    <div>
      <div id="fb-root"></div>
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id="493376487190267"
        theme_color="#0084ff"
        logged_in_greeting="Bonjour! Comment pouvons-nous vous aider?"
        logged_out_greeting="Bonjour! Connectez-vous pour commencer la discussion."
      ></div>
    </div>
  );
};

export default MessengerChat;
