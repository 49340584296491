import React, { useState } from "react";
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import FAQItem from "./FAQItem";

import translations from "../../../components/mulitlingue";





const FaqContent = () => {
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [activeTab, setActiveTab] = useState(lang.menu.general);

  const faqData = {
    [lang.menu.general]: [
      {
        question: lang.menu.generalQuestion1,
        answer: lang.menu.generalAnswer1,
      },
      {
        question: lang.menu.generalQuestion2,
        answer: lang.menu.generalAnswer2,
      },
      {
        question: lang.menu.generalQuestion3,
        answer: lang.menu.generalAnswer3,
      },
    ],
    [lang.menu.payment]: [
      {
        question: lang.menu.paymentQuestion1,
        answer: lang.menu.paymentAnswer1,
      },
      {
        question: lang.menu.paymentQuestion2,
        answer: lang.menu.paymentAnswer2,
      },
      {
        question: lang.menu.paymentQuestion3,
        answer: lang.menu.paymentAnswer3,
      },
    ],
    [lang.menu.support]: [
      {
        question: lang.menu.supportQuestion1,
        answer: lang.menu.supportAnswer1,
      },
      {
        question: lang.menu.supportQuestion2,
        answer: lang.menu.supportAnswer2,
      },
    ],
    [lang.menu.additional]: [
      {
        question: lang.menu.additionalQuestion1,
        answer: lang.menu.additionalAnswer1,
      },
      {
        question: lang.menu.additionalQuestion2,
        answer: lang.menu.additionalAnswer2,
      },
      {
        question: lang.menu.additionalQuestion3,
        answer: lang.menu.additionalAnswer3,
      },
      {
        question: lang.menu.additionalQuestion4,
        answer: lang.menu.additionalAnswer4,
      },
      {
        question: lang.menu.additionalQuestion5,
        answer: lang.menu.additionalAnswer5,
      },
      {
        question: lang.menu.additionalQuestion6,
        answer: lang.menu.additionalAnswer6,
      },
    ],
  };
  
  const handleTabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const renderFaqItems = (category) => {
    return faqData[category].map((item, index) => (
      <FAQItem key={index} question={item.question} answer={item.answer} />
    ));
  };

  return (
    <section className="section faq-section py-5">
      <Container>
        <Row className="justify-content-center mb-4">
          <Col lg={10}>
            <Nav className="faq-tabs nav-pills nav-fill justify-content-center" role="tablist">
              {Object.keys(faqData).map((key) => (
                <NavItem key={key}>
                  <NavLink
                    className={classnames({ active: activeTab === key })}
                    onClick={() => handleTabChange(key)}
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      textTransform: "capitalize",
                    }}
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
        </Row>

        {/* Section FAQ */}
        <Row className="faq-accordion-container mt-4">
          <Col lg={12}>
            <TabContent activeTab={activeTab}>
              {Object.keys(faqData).map((key) => (
                <TabPane key={key} tabId={key} className="fade show">
                  <div className="faq-box">
                    {renderFaqItems(key)}
                  </div>
                </TabPane>
              ))}
            </TabContent>
          </Col>
        </Row>

        <Row className="text-center mt-5">
          <Col>
            <Link to="/contact" className="btn btn-primary btn-lg">
              <i className="uil uil-phone"></i> {lang.menu.contacterNous}
            </Link>
            <Link to="mailto:contact@hirecue.com" className="btn btn-outline-warning ms-3 btn-lg">
              <i className="uil uil-envelope"></i> {lang.menu.envoiemail}
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FaqContent;


