import React from "react";
import "./PolitiqueConfidentialite.css";

const PolitiqueConfidentialiteContent = () => {
  return (
    <div className="politique-confidentialite-container">
      <h1>Politique de Confidentialité</h1>

      <p>
        Chez HireCue, nous nous engageons à protéger la confidentialité et la sécurité des informations personnelles de nos utilisateurs. Cette politique de confidentialité décrit comment nous collectons, utilisons et protégeons vos données dans le cadre de notre plateforme de recrutement.
      </p>

      <h2>1. Collecte des Informations</h2>
      <p>
        Nous collectons diverses informations vous concernant dans le cadre de l'utilisation de notre plateforme de recrutement, notamment :
      </p>
      <ul>
        <li>Les informations que vous fournissez lors de l'inscription en tant qu'entreprise, recruteur ou candidat.</li>
        <li>Les informations contenues dans votre CV, y compris votre expérience professionnelle, vos compétences et vos qualifications.</li>
        <li>Les données relatives à vos interactions avec les offres d'emploi, y compris les tests psychotechniques et les évaluations (techniques et QCM).</li>
        <li>Les données de navigation et d'utilisation de la plateforme, comme les pages visitées, les recherches effectuées, etc.</li>
      </ul>

      <h2>2. Utilisation des Informations</h2>
      <p>
        Les informations collectées sont utilisées pour les finalités suivantes :
      </p>
      <ul>
        <li>Permettre la gestion des comptes utilisateur (candidats, recruteurs, entreprises).</li>
        <li>Assurer la correspondance entre les compétences des candidats et les offres d'emploi en analysant les CV.</li>
        <li>Faciliter le processus de recrutement, y compris l'attribution de tests psychotechniques, des évaluations, et l'envoi d'invitations par e-mail aux candidats sélectionnés.</li>
        <li>Personnaliser votre expérience sur la plateforme en fonction de vos préférences et actions passées.</li>
        <li>Améliorer nos services et la performance de la plateforme à travers l'analyse des données de navigation et d'utilisation.</li>
      </ul>

      <h2>3. Protection des Informations</h2>
      <p>
        Nous mettons en œuvre des mesures techniques et organisationnelles pour protéger vos données personnelles contre tout accès non autorisé, toute modification, divulgation ou destruction. Cependant, aucune méthode de transmission de données sur Internet n'est totalement sécurisée, et nous ne pouvons garantir la sécurité absolue des informations transmises.
      </p>

      <h2>4. Partage des Informations</h2>
      <p>
        Nous ne partageons vos informations personnelles qu'avec les parties suivantes :
      </p>
      <ul>
        <li>Les recruteurs et les entreprises inscrites sur notre plateforme qui ont besoin de vos informations pour évaluer votre candidature.</li>
        <li>Les prestataires de services tiers qui nous aident à exploiter la plateforme (par exemple, services de stockage de données, services de messagerie, outils d'analyse).</li>
        <li>Les autorités compétentes en cas d'obligation légale ou de demande légitime.</li>
      </ul>

      <h2>5. Vos Droits</h2>
      <p>
        Conformément à la législation applicable sur la protection des données, vous disposez de plusieurs droits concernant vos données personnelles, notamment :
      </p>
      <ul>
        <li>Le droit d'accès à vos données personnelles.</li>
        <li>Le droit de rectifier ou de mettre à jour vos informations.</li>
        <li>Le droit de supprimer vos informations ou de demander leur limitation de traitement.</li>
        <li>Le droit de vous opposer au traitement de vos données dans certaines situations.</li>
      </ul>
      <p>
        Pour exercer ces droits, veuillez nous contacter à l'adresse suivante : <strong>contact@hirecue.com</strong>.
      </p>

      <h2>6. Cookies et Suivi</h2>
      <p>
        Nous utilisons des cookies pour améliorer votre expérience sur notre plateforme. Ces cookies nous aident à analyser l'utilisation du site et à personnaliser le contenu. Pour plus d'informations sur la gestion des cookies, veuillez consulter notre <a href="/PolitiqueCookies">Politique de Cookies</a>.
      </p>

      <h2>7. Modifications de la Politique de Confidentialité</h2>
      <p>
        Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page avec une nouvelle date de révision. Nous vous encourageons à consulter régulièrement cette politique pour être informé des mises à jour.
      </p>

      <h2>8. Contact</h2>
      <p>
        Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, n'hésitez pas à nous contacter :
      </p>
      <ul>
        <li>Par e-mail : <strong>contact@hirecue.com</strong></li>
        <li>Par téléphone : <strong>+49 172 8056166</strong></li>
        <li>Par courrier : <strong>14 D, Rue Emir Abdelkader, Tunis 1002, Tunisie</strong></li>
      </ul>

      <p>Dernière mise à jour : 12-11-2024</p>
    </div>
  );
};

export default PolitiqueConfidentialiteContent;
