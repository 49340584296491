import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody , Modal, Button, Form,  FormGroup, Label, Input } from "reactstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./pricing.css";
import axios from "axios";
import "./App.css";
import { MdHelpOutline } from 'react-icons/md';
import translations from "../../../components/mulitlingue";

const PricingPage = () => {
  const [pricingDisplay, setPricingDisplay] = useState(false);

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [selectedCurrency, setSelectedCurrency] = useState("EUR");
    const [exchangeRates, setExchangeRates] = useState({});
    const [exchangeRatesPayme, setExchangeRatesPayme] = useState({});
    useEffect(() => {

      const fetchPricingDisplay = async () => {
				try {
					const responseSub = await axios.get(
						`${process.env.REACT_APP_APP_DOMAIN}/api/users/pricing/getPricingDisplay`
					);
					setPricingDisplay(responseSub.data.PricingDisplay); 
				} catch (error) {
					console.error('Error fetching PricingDisplay data:', error);
				}
			};
      fetchPricingDisplay()
    }, []);

    useEffect(() => {
      const fetchExchangeRates = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/exchanges/getAll`);
          const ratesData = response.data;
    
          const commercialRate = ratesData.find(rate => rate.ExchangeType === "Commercial rate");
          const referenceConversionRate = ratesData.find(rate => rate.ExchangeType === "Reference Conversion Rate");
    
          if (commercialRate) {
            setExchangeRates({
              EUR: commercialRate.EUR,
              USD: commercialRate.USD,
              TND: commercialRate.TND,
              GBP: commercialRate.GBP
            });
          }
    
          if (referenceConversionRate) {
            setExchangeRatesPayme({
              EUR: referenceConversionRate.EUR,
              USD: referenceConversionRate.USD,
              TND: referenceConversionRate.TND,
              GBP: referenceConversionRate.GBP
            });
          }
        } catch (error) {
          console.error("Error fetching exchange rates:", error);
        }
      };
    
      fetchExchangeRates();
    }, []);
    async function detectCurrency() {
      try {
        const response = await fetch('https://api.ipgeolocation.io/ipgeo?apiKey=9e3b45451ec84066bbe6ab79f68749c1');
        if (!response.ok) {
          throw new Error('API unavailable');
        }
        const data = await response.json();
        return data.currency ? data.currency.code : detectCurrencyByLocale();
      } catch (error) {
        console.warn('API unavailable, falling back to locale');
        return detectCurrencyByLocale();
      }
    }
    
    function detectCurrencyByLocale() {
      const language = navigator.language || navigator.userLanguage;
      const currencyMapping = {
        'fr-FR': 'EUR',
        'fr-TN': 'TND',
        'en-US': 'USD',
      };
      return currencyMapping[language] || 'EUR'; // Default to USD
    }

  



  const [selectedBilling, setSelectedBilling] = useState(lang.menu.monthly);
  const [subscriptionType, setSubscriptionType] = useState([]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [subscriptionfeaturespertypes, setSubscriptionfeaturespertypes] = useState([]);
  const [activeView, setActiveView] = useState("entreprise"); 
 
  
   useEffect(() => {
    detectCurrency().then(currency => {
      console.log("Detected currency:", currency);
      setSelectedCurrency(currency);
    });
    
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionType/getAll`);
        setSubscriptionType(response.data);
        console.log("subscriptions:",response.data)
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypes`);
        setSubscriptionfeaturespertypes(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const calculateConvertedPrice = (price, currency) => {
    if (!price) return "0.00"; 
  
    const cleanedPrice = parseFloat(
      price.replace(/[^\d.,]/g, "").replace(",", ".")
    );

    if (isNaN(cleanedPrice)) return "0.00"; 

    

    return (cleanedPrice * exchangeRates[currency] ).toFixed(2);
  }; 
  const calculateConvertedPricereduction = (price, currency,promoPercentage) => {
    if (!price) return "0.00"; 
  
    let cleanedPrice = parseFloat(
      price.replace(/[^\d.,]/g, "").replace(",", ".")
    );

    if (isNaN(cleanedPrice)) return "0.00"; 
    let rapportPercentage=(100-promoPercentage)/100
    cleanedPrice=cleanedPrice*rapportPercentage

    return (cleanedPrice * exchangeRates[currency] ).toFixed(2);
  };
  
const subscriptionLinks = {
  Free: "https://buy.stripe.com/test_cN2eYt5Gi4tl8LufZ5",
  "Solo Starter": "https://buy.stripe.com/test_6oE4jP7Oq8JBaTCbIJ",
  "Solo Pro": "https://buy.stripe.com/test_8wMaId4Ce9NF9Py7su",
  "Solo Premium": "https://buy.stripe.com/test_6oE7w10lY4tle5OeUX",
  "Entreprise Starter": "https://buy.stripe.com/test_28o4jP4Ce7Fx8Lu9AE",
  "Entreprise Pro": "https://buy.stripe.com/test_9AQbMhd8K8JBgdW7sx",
  "Entreprise Premium": "https://buy.stripe.com/test_14k6rX1q23phf9SbIO",
  "Pay as you go": "https://buy.stripe.com/test_bIYdUp0lYf7Z3ra144"
};

  const PurchaseLink = ({ subscriptionType }) => {
    const link = subscriptionLinks[subscriptionType];
    if (!link) return null;
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" className="btn-soft-primary rounded-pill">
        Purchase Now <i className="uil uil-arrow-right"></i>
      </a>
    );
  };
  const filteredSubscriptionType = subscriptionType.filter(subscription => {
    if (activeView === "solo") {
        return subscription.Type.toLowerCase().includes("solo") || 
               subscription.Type.toLowerCase() === "free" || 
               subscription.Type.toLowerCase() === "pay as you go";
    } else if (activeView === "entreprise") {
        return subscription.Type.toLowerCase().includes("entreprise") || 
               subscription.Type.toLowerCase() === "free" || 
               subscription.Type.toLowerCase() === "pay as you go";
    }
    return false;
});
const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
const [formData, setFormData] = useState({ firstname:"",lastname:"",email: "", phone: "", type: "General demo", description: "" }); // Form data

const toggleModal = () => setIsModalOpen(!isModalOpen);

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({ ...prevData, [name]: value }));
};
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

const handlePurchaseClick = () => {
  toggleModal(); 
};
const handleFormSubmit = (e) => {
  e.preventDefault(); 
  
  fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/requestDemo/createRequestDemo`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      console.log("response :", response);
      if (response.ok) {
        setSuccessMessage("Request submitted successfully!");
        setErrorMessage(""); 
        window.location.href = `${process.env.REACT_APP_APP_DOMAIN}/page-register?email=${formData.email}&firstname=${formData.firstname}&lastname=${formData.lastname}`;

      } else {
        setSuccessMessage("");
        throw new Error("Request failed");
      }
    })
    .catch((error) => {
      setErrorMessage("Failed to submit request. Please try again.");
      setSuccessMessage(""); 
    });
};
const SubscriptionCard = ({ subscription, selectedCurrency, subscriptionfeaturespertypes, handlePurchaseClick }) => {
  const [selectedPeriodicity, setSelectedPeriodicity] = useState(subscription.Periodicity[0]);
  if (!subscription || !subscription.Periodicity || !subscriptionfeaturespertypes) {
    return null; 
  }
  const handlePeriodChange = (event) => {
    const newPeriod = subscription.Periodicity.find(
      (period) => period.periode === event.target.value
    );
    setSelectedPeriodicity(newPeriod);
  };

  const originalPrice = calculateConvertedPrice(selectedPeriodicity.prix, selectedCurrency);
  const isPromo = selectedPeriodicity.isPromo;
  const promoPercentage = selectedPeriodicity.PromoPercentage;
  const discountedPrice = isPromo
    ? calculateConvertedPricereduction(selectedPeriodicity.prix, selectedCurrency, promoPercentage)
    : originalPrice;

  return (
    <Col lg={4} md={6} className="mt-4">
      <Card className="pricing-box bg-white shadow-sm h-100 rounded-3 position-relative">
        {isPromo && (
          <div className="badge-promo bg-danger text-white position-absolute">
           -{promoPercentage }%
          </div>
        )}
        <CardBody className="p-4 px-lg-5">
          <div className="pricing-icon bg-light rounded-circle icons-md mx-auto">
            <Icon icon="uim-telegram-alt" className="text-primary" />
          </div>
          <h4 className="text-center text-dark mt-4">
            {subscription.Type === "Pay as you go" ? "Customized offer" : subscription.Type}
          </h4>
          <select
            className="form-select mb-3"
            onChange={handlePeriodChange}
            value={selectedPeriodicity.periode}
          >
            {subscription.Periodicity.map((period) => (
              <option key={period.periode} value={period.periode}>
                {period.periode}
              </option>
            ))}
          </select>
          <div className="text-center mt-3">
            {isPromo ? (
              <div>
                <h4 className="text-muted fw-light text-decoration-line-through">
                  {originalPrice}{selectedCurrency} 
                </h4>
                <h2 className="text-primary fw-bold">
                  {discountedPrice} <small className="fs-16 text-muted"> {selectedCurrency}</small>
                </h2>
              </div>
            ) : (
              <h4 className="text-primary fw-bold">
                {originalPrice} <small className="fs-16 text-muted"> {selectedCurrency}</small>
              </h4>
            )}
          </div>
          <ul className="list-unstyled mt-4">
            {subscriptionfeaturespertypes
              .filter(
                (feature) =>
                  feature.type_id === subscription.id &&
                  feature.feature_available === 1 &&
                  feature.feature_quota_max !== null
              )
              .map((feature) => (
                <li key={feature.feature_id} className="mb-2 d-flex align-items-center">
                  <i className="mdi mdi-check-circle-outline text-success me-2"></i>
                  <span>
                    {feature.feature_quota_max} {feature.feature_name}
                  </span>
                </li>
              ))}
          </ul>
          <div className="text-center mt-4">
            <button className="btn btn-primary px-4 py-2 rounded-pill" onClick={handlePurchaseClick}>
              Purchase Now
            </button>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};


  return (
    <React.Fragment>

{pricingDisplay ? (  
  <>
   <section className="section py-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center">
                <h1 className="badge bg-warning-subtle text-warning fs-15 mb-2">
                  {lang.menu.choosePlan}
                </h1>
                <p className="text-muted">
                {lang.menu.choosePlanDesc}
                </p>
              </div>
            </Col>
          </Row>
        {/*   <div className="text-center mt-3">
            <button
              className={`btn btn-outline-secondary me-2 ${selectedCurrency === "EUR" ? "active" : ""}`}
              onClick={() => setSelectedCurrency("EUR")}
            >
              EUR
            </button>
            <button
              className={`btn btn-outline-secondary me-2 ${selectedCurrency === "USD" ? "active" : ""}`}
              onClick={() => setSelectedCurrency("USD")}
            >
              USD
            </button>
            <button
              className={`btn btn-outline-secondary me-2 ${selectedCurrency === "TND" ? "active" : ""}`}
              onClick={() => setSelectedCurrency("TND")}
            >
              TND
            </button>
            <button
              className={`btn btn-outline-secondary ${selectedCurrency === "GBP" ? "active" : ""}`}
              onClick={() => setSelectedCurrency("GBP")}
            >
              GBP
            </button>
          </div> */}

       {/*    <div className="text-center mt-3">
            <label className={`lunar-solar-switch ${selectedBilling === lang.menu.annual ? "active" : ""}`}>
              <input
                type="checkbox"
                className="visually-hidden"
                checked={selectedBilling === lang.menu.annual}
                onChange={() => setSelectedBilling(selectedBilling === lang.menu.monthly ? lang.menu.annual : lang.menu.monthly)}
              />
              <span className="lunar-solar-label">
                {selectedBilling === lang.menu.monthly ? lang.menu.monthly : lang.menu.annual}
              </span>
            </label>
          </div> */}
          <div className="text-center mt-3">
          <Button 
    color="primary" 
    className={`lunarSwitcher ${activeView === "entreprise" ? "active" : ""}`} 
    onClick={() => setActiveView("entreprise")}
>
    Entreprise
</Button>

<Button 
    color="primary" 
    className={`lunarSwitcher ${activeView === "solo" ? "active" : ""}`} 
    onClick={() => setActiveView("solo")}
>
    Solo
</Button>

</div>



<Row className="pricing-grid mt-5">
  {filteredSubscriptionType.map((subscription) => (
    <SubscriptionCard
      key={subscription.id}
      subscription={subscription}
      selectedCurrency={selectedCurrency}
      subscriptionfeaturespertypes={subscriptionfeaturespertypes}
      handlePurchaseClick={handlePurchaseClick}
    />
  ))}
</Row>


        </Container>
        
      </section>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Purchase Details</h5>
          <button type="button" className="btn-close" onClick={toggleModal}></button>
        </div>
        <Form onSubmit={handleFormSubmit} className="p-3">
        <FormGroup>
            <Label for="email">Firstname</Label>
            <Input type="text" name="firstname" id="firstname" value={formData.firstname} onChange={handleInputChange} required />
          </FormGroup>
          <FormGroup>
            <Label for="email">Lastname</Label>
            <Input type="text" name="lastname" id="lastname" value={formData.lastname} onChange={handleInputChange} required />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input type="email" name="email" id="email" value={formData.email} onChange={handleInputChange} required />
          </FormGroup>
          <FormGroup>
            <Label for="phone">Phone</Label>
            <Input type="text" name="phone" id="phone" value={formData.phone} onChange={handleInputChange} required />
          </FormGroup>
          <FormGroup>
            <Label for="type">Type</Label>
            <Input 
    type="select" 
    name="type" 
    id="type" 
    value={formData.type} 
    onChange={handleInputChange} 
    required
  >
    <option value="">{lang.menu.selectOption}</option> {/* Placeholder option */}
    <option value="General demo">{lang.menu.Generaldemo}</option>
    <option value="Custom demo">{lang.menu.Customdemo}</option>
  </Input>          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input type="textarea" name="description" id="description" value={formData.description} onChange={handleInputChange} />
          </FormGroup>
          <Button type="submit" color="primary">
            Submit
          </Button>
        </Form>
      </Modal>
  </>) : (<></>)}

     
    </React.Fragment>
  );
};

export default PricingPage;
