import React from "react";
import Section2 from "./Section2";
import Offres from "./Offres";

const OffresList = () => {
  document.title = "Job offers | HireCue  ";
  return (
    <React.Fragment>
      <Section2 />
      <Offres/>
   
      <br/>
    </React.Fragment>
  );
};

export default OffresList;
