import React from "react";
import SectionP from "./SectionP";
import PolitiqueCookiesContent from "./PolitiqueCookiesContent";

const PolitiqueCookies = () => {
  document.title = "Politique de Cookies | HireCue - Job Listing ";
  return (
    <React.Fragment>
      <SectionP />
      <PolitiqueCookiesContent />
    </React.Fragment>
  );
};

export default PolitiqueCookies;
