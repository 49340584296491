import React from "react";
import Section from "./Section";
import PolitiqueConfidentialiteContent  from "./PolitiqueConfidentialiteContent";

const PolitiqueConfidentialite  = () => {
  document.title = "Politique Confidentialite | HireCue  ";
  return (
    <React.Fragment>
      <Section />
      <PolitiqueConfidentialiteContent  />
    </React.Fragment>
  );
};

export default PolitiqueConfidentialite ;
